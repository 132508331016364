export const fadeInOutDuration = 300;

export const stripeCardStyle = {
  base: {
    color: "#5e5e5e",
    fontSmoothing: "antialiased",
    fontSize: "16px",
    backgroundColor: "#fff",
    "::placeholder": {
      color: "#bebebe",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

import { fadeInOutDuration } from "./variables";

$(() => {
  $(".flash--notification").slideDown(fadeInOutDuration);
  setTimeout(() => {
    $(".temporary--notification").slideUp(fadeInOutDuration);
  }, 6000);
  $(".flash--notification-close-button").on("click", () => {
    $(".flash--notification").slideUp(fadeInOutDuration);
  });
});
